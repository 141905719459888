import { LanguagesProficiencyType, LanguageType, DesireToChangeJobTye } from '../type/Common'
import { JobStageType } from '../type/JobType'
import {
  JOB_STATUS_EXPIRED,
  ENUM_JOB_FREEC_STATUS_NUMBER,
  JOB_STATUS_REJECTED,
  ENUM_JOB_STATUS,
  JOB_STATUS_PUBLISH,
  JOB_STATUS_REVIEWING,
  JOB_STATUS_CLOSED
} from './Enum'

export const formatCurrency = (amount) => {
  const value = parseInt(amount).toString()
  return value.split(/(?=(?:\d{3})+(?:\.|$))/g).join(',')
}
export function languageCode2Name(code: string): string {
  return { vi: 'VN', en: 'EN', jp: 'JP' }[code]
}
export function stripHTML(originalString: string): string {
  return originalString.replace(/(<([^>]+)>)/gi, '')
}
export function getUserIndexPage(userType: number): string {
  // const userCode = { 0: 'company', 1: 'candidate' }[userType] || 'anonymos'
  // const meta = config.security.role_metas.find((meta) => meta.role === userCode)
  return '/'
}

export function toStringFromQuery(params?: string | string[]): string {
  return params ? (typeof params === 'string' ? params : params[0]) : undefined
}
export function toIntFromQuery(params?: string | string[]): number {
  return params ? (typeof params === 'string' ? parseInt(params) : parseInt(params[0])) : undefined
}
export function toIntArrayFromQuery(params: string[]): number[] {
  return params ? (typeof params === 'string' ? [parseInt(params)] : params.map((s) => parseInt(s))) : []
}

export function escapedParams(params: object): any {
  return Object.keys(params).reduce(
    (final, key) => ({ ...final, [key]: typeof params[key] === 'string' ? encodeURIComponent(params[key]) : params[key] }),
    {}
  )
}
export function descapedParams(params: object): any {
  return Object.keys(params).reduce(
    (final, key) => ({ ...final, [key]: typeof params[key] === 'string' ? decodeURIComponent(params[key]) : params[key] }),
    {}
  )
}
const checkParam = (param) => {
  return param !== '' && param !== undefined && param != null
}
export function removeEmptySpaceProp(params: object): object {
  return Object.keys(params).reduce((final, key) => ({ ...final, ...(checkParam(params[key]) ? { [key]: params[key] } : {}) }), {})
}

function nomarlizeQueryName(key: string) {
  return key.match(/\[\]/) ? key.replace('[]', '') : key
}
export function nomarlizeQuery(query: object): any {
  return Object.keys(query).reduce((final, key) => ({ ...final, [nomarlizeQueryName(key)]: query[key] }), {})
}
export const EmplymentList = [
  { id: 0, name: 'Full time', name_vn: 'Toàn thời gian' },
  { id: 1, name: 'Part time', name_vn: 'Bán thời gian' },
  { id: 2, name: 'Internship', name_vn: 'Thực tập sinh' },
  { id: 5, name: 'Freelancer', name_vn: 'Làm việc tự do' }
]
export const EmployerTypeMap = EmplymentList.reduce((result, lang) => ({ ...result, [lang.id]: lang.name }), {})

export function employmentText(type: number, language: string) {
  const temp = {
    0: { name: 'Full time', name_vn: 'Toàn thời gian' },
    1: { name: 'Part time', name_vn: 'Bán thời gian' },
    2: { name: 'Internship', name_vn: 'Thực tập sinh' },
    5: { name: 'Freelancer', name_vn: 'Làm việc tự do' },
    9: { name: 'Work remote', name_vn: 'Làm việc từ xa' },
    6: { name: 'Hybrid work', name_vn: 'Làm việc hybrid' }
  }[type]
  return temp ? (language === 'en' ? temp.name : temp?.name_vn || 'unknown') : 'unknown'
}
export function nomarlizeNotNullProperties(args: object): any {
  return Object.keys(args).reduce((result, key) => (args[key] ? { ...result, [key]: args[key] } : result), {})
}
export const ListDegree = [
  { id: 0, name: 'Diploma', name_vn: 'Bằng Cao đẳng' },
  { id: 1, name: 'Bachelor', name_vn: 'Bằng Cử nhân' },
  { id: 2, name: 'Master', name_vn: 'Bằng Thạc sĩ' },
  { id: 3, name: 'Doctoral', name_vn: 'Bằng Tiến sĩ' },
  { id: 4, name: 'Online', name_vn: 'Bằng đào tạo trực tuyến/từ xa' }
]

export const DegreeMap = ListDegree.reduce((result, degree) => ({ ...result, [degree.id]: degree }), {})

export const LanguagesProficiencys: LanguagesProficiencyType[] = [
  { name: 'Basic', name_vn: 'Cơ bản', id: 0 },
  { name: 'Intermediate', name_vn: 'Trung cấp', id: 1 },
  { name: 'Advanced', name_vn: 'Cao cấp', id: 2 },
  { name: 'Expert', name_vn: 'Chuyên gia', id: 3 }
]
export const LanguagesProficiencyMap = LanguagesProficiencys.reduce((result, lp) => ({ ...result, [lp.id]: lp.name }), {})

export const languageList: LanguageType[] = [
  { id: 0, name: 'English', name_vn: 'Tiếng Anh' },
  { id: 1, name: 'Japanese', name_vn: 'Tiếng Nhật' },
  { id: 2, name: 'Korean', name_vn: 'Tiếng Hàn' },
  { id: 3, name: 'French', name_vn: 'Tiếng Pháp' },
  { id: 4, name: 'Chinese', name_vn: 'Tiếng Trung' },
  { id: 5, name: 'Thai', name_vn: 'Tiếng Thái' },
  { id: 6, name: 'Spanish', name_vn: 'Tiếng Tây Ban Nha' },
  { id: 7, name: 'Russian', name_vn: 'Tiếng Nga' },
  { id: 8, name: 'Italian', name_vn: 'Tiếng Ý' },
  { id: 9, name: 'Hindi', name_vn: 'Tiếng Ấn Độ' },
  { id: 10, name: 'German', name_vn: 'Tiếng Đức' }
]

export const DesireToChangeJobs: DesireToChangeJobTye[] = [
  { id: 0, name: "I don't want to change a job.", name_vn: 'Tôi chưa muốn thay đổi công việc.' },
  { id: 1, name: 'I will consider if there is a better opportunity.', name_vn: 'Tôi sẽ cân nhắc nếu có cơ hội tốt hơn.' },
  { id: 2, name: 'I am actively looking for a job.', name_vn: 'Tôi muốn tìm việc ngay.' }
]
export const LanguageMap = languageList.reduce((result, lang) => ({ ...result, [lang.id]: lang.name }), {})

export const toBase64 = (file: File): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
export function formatMoney(amount: any, decimalCount = 0, decimal = '.', thousands = ','): string {
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount

  const negativeSign = amount < 0 ? '-' : ''

  const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
  const j = i.length > 3 ? i.length % 3 : 0

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        //@ts-ignore
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : '')
  )
}
export function limitViewCharactor(text: string, limit = 25): string {
  return text && text.length > limit ? text.substring(0, limit) + '...' : text
}

type IndustrySerializeType = {
  id: string
  attributes: { id: number; name: string; name_vn: string }
}
export function sortIndustry(industryList: IndustrySerializeType[]): IndustrySerializeType[] | undefined {
  if (!industryList) return
  return industryList?.sort((catA, catB) => catA.attributes.name.localeCompare(catB.attributes.name))
}

export const FreeCStatusMap = {
  0: 'Reviewing',
  1: 'Approved',
  2: 'Rejected',
  3: 'Expired'
}
export function jobStatusFromFreeCStatusAndStatus(freec_status: number, status: number): string {
  if (status === ENUM_JOB_STATUS.closed) {
    return JOB_STATUS_CLOSED
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.approved && status === ENUM_JOB_STATUS.published) {
    return JOB_STATUS_PUBLISH
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.pending && status === ENUM_JOB_STATUS.published) {
    return JOB_STATUS_REVIEWING
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.expired) {
    return JOB_STATUS_EXPIRED
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.rejected) {
    return JOB_STATUS_REJECTED
  }
}

export function jobOrderStatusFromFreeCStatusAndStatus(freec_status: number, status: number): string {
  if (status === ENUM_JOB_STATUS.closed) {
    return JOB_STATUS_CLOSED
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.approved && status === ENUM_JOB_STATUS.published) {
    return JOB_STATUS_PUBLISH
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.pending && status === ENUM_JOB_STATUS.published) {
    return JOB_STATUS_REVIEWING
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.expired) {
    return JOB_STATUS_EXPIRED
  }
  if (freec_status === ENUM_JOB_FREEC_STATUS_NUMBER.rejected) {
    return JOB_STATUS_REJECTED
  }
}

export function isHTML(content: string): boolean {
  if (content && content.match('</[^>]+>')) {
    return true
  }
  return false
}
export function createIdSlug(slug: string, id: number | string): string {
  return `${slug}-i${id}`
}
export function createNewCompanyIdSlug(slug: string, id: number | string): string {
  return `${slug}-fi${id}`
}
export enum FORM_PRODUCT {
  NATIVE_APP = 0,
  WEB_MOBILE = 1,
  WEB_DESKTOP = 2
}

export function checkCvFileType(fileName?: string, type?: string): boolean {
  if (!fileName) return false
  return !!fileName.match(`\\.${type}`)
}

export const isMobileBrowser = (): boolean => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
    //@ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}
export function createCancelablePromise<T, V>(action: (args: T) => Promise<V>) {
  let isCancel = false
  return {
    run: (args: T) =>
      action(args).then((rs) => {
        if (isCancel) {
          // return Promise.reject('promise was cancel')
        } else {
          return Promise.resolve(rs)
        }
      }),
    cancel: () => {
      isCancel = true
    }
  }
}

export function removeAccents(str: string) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}

export function nonAccentVietnamese(str) {
  if (!str || typeof str !== 'string') {
    return ''
  }
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}

export function toStringArray(params: string | string[]): string[] {
  return typeof params === 'string' ? [params] : params
}

export function getSearchParam(params) {
  if (typeof window !== 'undefined') {
    const getSearchParamFromUrl = document.location.search.substring(1)
    const searchParams = new URLSearchParams(getSearchParamFromUrl)
    return searchParams.get(params)
  }

  return ''
}
export const listSearchJobState: JobStageType[] = [
  { id: 1, stage_label: 'Applied', stage_type_id: 1, stage_label_vn: 'Ứng tuyển' },
  { id: 2, stage_label: 'Screen', stage_type_id: 3, stage_label_vn: 'Sàng lọc' },
  { id: 3, stage_label: 'Hired', stage_type_id: 7, stage_label_vn: 'Đã tuyển' }
]

export const splitUrl = (url, textBreakLength = 30) => {
  if (!url) return ''
  if (url.length < textBreakLength) return url
  if (url.length > textBreakLength * 2) {
    const appendLine =
      url.slice(0, textBreakLength) + ' ' + url.slice(textBreakLength, textBreakLength * 2) + ' ' + url.slice(textBreakLength * 2)
    return appendLine
  }
  const appendLine = url.slice(0, textBreakLength) + ' ' + url.slice(textBreakLength)
  return appendLine
}
